var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',{staticClass:"mx-auto"},[_c('v-img',{attrs:{"src":_vm.amphiEvent.image,"height":"200px"}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.amphiEvent.name)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.amphiEvent.category)+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"orange lighten-2","text":""},on:{"click":function($event){_vm.description = !_vm.description}}},[_vm._v(" Leer descripción ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.description = !_vm.description; }}},[_c('v-icon',[_vm._v(_vm._s(_vm.description ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.description),expression:"description"}]},[_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.amphiEvent.description)+" ")])],1)])],1),_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.schedules,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Eventos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function (){ return _vm.schedule=true; }}},'v-btn',attrs,false),on),[_vm._v(" Nuevo Horario ")])]}}]),model:{value:(_vm.schedule),callback:function ($$v) {_vm.schedule=$$v},expression:"schedule"}},[_c('new-schedule',{attrs:{"event_id":_vm.$route.params.id},on:{"cancel":_vm.closeModals}})],1)],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],attrs:{"text":"","prominent":"","type":"error","icon":"mdi-cloud-alert"}},[_vm._v(" No ha sido posible eliminar el horario. "+_vm._s(_vm.errorMessage)+" ")])]},proxy:true},{key:"item.schedule",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.schedule).locale('es').format('LLLL'))+" ")]}},{key:"item.capacity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.capacity.toFixed(2))+" % ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.$router.push({ name: 'AdminSchedule', params: { id: item.id } }); }}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function () { return _vm.deleteItem(item.id); }}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }