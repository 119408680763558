<template>
  <div>
    <v-container>
      <v-card
          class="mx-auto"
      >
        <v-img
            :src="amphiEvent.image"
            height="200px"
        ></v-img>

        <v-card-title>
          {{ amphiEvent.name }}
        </v-card-title>
        <v-card-subtitle>
          {{ amphiEvent.category }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
              color="orange lighten-2"
              text
              @click="description = !description"
          >
            Leer descripción
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              icon
              @click="() => description = !description"
          >
            <v-icon>{{ description ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="description">
            <v-divider></v-divider>

            <v-card-text>
              {{amphiEvent.description}}
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
      <div class="mt-4">
        <v-data-table
            :headers="headers"
            :items="schedules"
            :loading="loading"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Eventos</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="schedule"
                  max-width="700px"
              >
                <new-schedule @cancel="closeModals" :event_id="$route.params.id"></new-schedule>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="ma-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="()=>schedule=true"
                  >
                    Nuevo Horario
                  </v-btn>
                </template>
              </v-dialog>
<!--             CARGA MASIVA -->

            </v-toolbar>
            <v-alert
                text
                prominent
                type="error"
                icon="mdi-cloud-alert"
                v-show="errorMessage"
            >
              No ha sido posible eliminar el horario.  {{errorMessage}}
            </v-alert>
          </template>

          <template v-slot:item.schedule="{item}">
            {{ $moment(item.schedule).locale('es').format('LLLL')}}
          </template>
          <template v-slot:item.capacity="{item}">
            {{ item.capacity.toFixed(2) }} %
          </template>
          <template v-slot:item.actions="{item}">
            <v-icon
                small
                class="mr-2"
                @click="() => $router.push({ name: 'AdminSchedule', params: { id: item.id } })"

            >
              mdi-eye
            </v-icon>
            <v-icon
                small
                @click="() => deleteItem(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>

    </v-container>
  </div>
</template>

<script>
import {AmphiEvents, AmphiSchedules} from "@/services/admin.service";
import NewSchedule from "@/views/admin/NewSchedule";

export default {
  name: "Evento",
  components: {NewSchedule},

  data(){
    return {
      amphiEvent: {},
      schedules: [],
      description:false,
      headers: [
        { text: 'ID', value: 'id'},
        { text: 'fecha', value: 'schedule' },
        { text: 'Mesas Reservadas', value: 'tickets' },
        { text: 'Ocupacion', value: 'capacity' },
        { text: 'Acciones', value:'actions', sortable:false },
      ],
      masive:false,
      schedule: false,
      loading: false,
      errorMessage: null,

    }
  },
  mounted() {
    this.getEvent(this.$route.params.id)
  },
  metaInfo() {
    return {
      title: `${this.amphiEvent.name || 'Evento'} | Administrador`,
      meta: [
        {name: 'description', content: this.amphiEvent.description},
      ]
    }
  },
  methods:{
    getEvent(id){
      this.loading = true;
      const schedule = AmphiSchedules().get()
      const event =   AmphiEvents().retrieve(id)
      const reservations = AmphiEvents().get_reservations()
      Promise.all([schedule,event, reservations]).then(
          (response) => {
            this.amphiEvent = response[1].data.data
            this.schedules = response[0].data.data.filter(
                elem => elem.amphi_event_id == id
            )
            this.tickets = response[2].data.data
            this.schedules.forEach( schedule =>{
              schedule.tickets = this.tickets.filter(
                  reservation => reservation.event_schedule_id.id === schedule.id && reservation.receipt
              ).length
              schedule.capacity = (schedule.tickets / 162) * 100 ;
            })
            this.$store.commit('setLoading', false)
            this.loading = false
          }
      )
    },
    closeModals(){
      this.getEvent(this.$route.params.id);
      this.masive = false
      this.schedule = false
    },
    deleteItem(id){
      AmphiSchedules().delete(id).then(
          () =>  this.getEvent(this.$route.params.id)
      ).catch(
        e =>  {
          this.errorMessage = e
          setTimeout(()=> this.errorMessage = null, 5000)
        }
      )
    }
  }
}
</script>

<style scoped>

</style>